import React from "react";
import "./WebStyles/Swipersection.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import SwiperCore, { Navigation, Pagination, Scrollbar } from "swiper";

import mobicon4 from "./WebAssets/Group 1834.png";
import mobicon1 from "./WebAssets/Group 1820.png";
import mobicon2 from "./WebAssets/Group 1823.png";
import mobicon3 from "./WebAssets/Group 1830.png";
SwiperCore.use([Navigation, Pagination, Scrollbar]);

export default function Swipersection() {
  return (
    <div className="swiper-sec">
      <div className="container swiper-wrapper">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar]}
          spaceBetween={1}
          slidesPerView={1}
          pagination={{ clickable: true }}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          breakpoints={{
            // when window width is >= 576px (mobile)
            576: {
              slidesPerView: 1, // Adjust the number of slides per view for mobile
              spaceBetween: 0 // Adjust the space between slides for mobile
            },
            // when window width is >= 768px (tablet)
            768: {
              slidesPerView: 1, // Adjust the number of slides per view for tablet
              spaceBetween: 50 // Adjust the space between slides for tablet
            },
            // when window width is >= 1024px (desktop)
            1024: {
              slidesPerView: 1, // Keep the original number of slides per view for desktop
              spaceBetween: 50 // Keep the original space between slides for desktop
            }
          }}
        >
          <SwiperSlide>
            <div class="swipercard">
              <div class="swipercard-left">
                <h6>Offer Flexible Scheduling Options</h6>
                <p>
                  Providing clients with a variety of choices and options when
                  it comes to scheduling appointments or services. This can
                  include allowing customers to select specific dates and times
                  that suit them, offering online and offline booking options,
                  accommodating last-minute appointments, and providing the
                  flexibility to reschedule or cancel appointments as needed.{" "}
                </p>
              </div>
              <div class="swipercard-right">
                <img src={mobicon1} />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div class="swipercard">
              <div class="swipercard-left">
                <h6>Easily monitor and update all reservations in real time</h6>
                <p>
                  The ability to easily track all bookings or appointments and
                  make changes as they occur, with updates appearing instantly.
                  This capability allows businesses to get a clear overview of
                  their booking status, manage their schedule efficiently, and
                  make the necessary adjustments instantly.
                </p>
              </div>
              <div class="swipercard-right">
                <img src={mobicon2} />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div class="swipercard">
              <div class="swipercard-left">
                <h6>Implement a powerful reservation tracking system</h6>
                <p>
                  Create a robust and effective system to effectively monitor
                  and manage bookings or appointments. This system should be
                  able to track reservations in real time, provide detailed
                  information about each reservation, manage availability, send
                  notifications or reminders to customers and employees, and
                  allow for easy updates or modifications as needed.
                </p>
              </div>
              <div class="swipercard-right">
                <img src={mobicon3} />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div class="swipercard">
              <div class="swipercard-left">
                <h6>
                  Payment details and the ability to pay using multiple methods.
                </h6>
                <p>
                  Providing the ability to pay using multiple methods enhances
                  convenience and meets diverse customer preferences. This may
                  include traditional methods such as credit or debit cards, as
                  well as digital payment solutions such as mobile wallets,
                  online banking or payment gateways.{" "}
                </p>
              </div>
              <div class="swipercard-right">
                <img src={mobicon4} />
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}
