import React, { useEffect, useState } from "react";
import "./WebStyles/business.css";
import businessplan from "./WebAssets/Asset 1@4x 2.png";
import checkmark from "./WebAssets/check-box 1.png";
import axios from "axios";
import { Link } from "react-router-dom";

export default function Businessplan() {
  const [data, setData] = useState([]);
  const [packageDetails, setPackageDetails] = useState("");
  const [features, SetFeatures] = useState([]);


  useEffect(() => {
    axios
      .get("https://carwashapis.gosmart.ae/get_all/carwash_package")
      .then((response) => {
        const fetchedData = response.data.data[0];
        setData(fetchedData);
      })
      .catch((error) => {
        console.error("Error fetching the package details:", error);
      });
  },[]);


  useEffect(() => {
 

  axios.get("https://carwashapis.gosmart.ae/get_all/package_featuers")
      .then((response) => {
        const fetchedData = response.data.data[0];
        SetFeatures(fetchedData);
        setPackageDetails(fetchedData.featuers);
      })
      .catch((error) => {
        console.error("Error fetching the package details:", error);
      })
  
    }
  
  , []);

  const detailsArray = packageDetails ? packageDetails.split(",") : [];

  return (
    <div>
      <div className="business-sec" id="Pricing">
        <div className="container business-sec-center-text">
          <h6>Your Business Plan</h6>
          <button class="mb-5"></button>
        </div>

        <div className="container business-sec-grid">
          <div className="business-sec-left">
            <img src={businessplan} />
          </div>

          <div className="business-sec-right">
            <div className="Plans-card">
              {data && (
                <div class="Plans-card-text">
                  <h5>Subscribe for {data.package_name} Plan</h5>
                  {/* <h6>{data.package_period}</h6>
                  <span>$ {data.package_cost}</span> */}
                  {/* <p className="pack-dsc">$ {features.featuers}</p> */}
                </div>
              )}

              <div className="check-sec">
                {detailsArray.map(
                  (detail, index) =>
                    detail && (
                      <div key={index} className="check-mark-card">
                        <p className="check-mar-card">
                          <img
                            src={checkmark}
                            alt="Checkmark"
                            style={{ marginRight: "8px" }}
                          />
                          {detail}
                        </p>
                      </div>
                    )
                )}

<Link to="/signup"><button>Register Now </button></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
