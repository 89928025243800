import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import applicationsimg from "./WebAssets/appcardimg.png";
import "./WebStyles/Signup.css";
import RegisterationForm from "./RegisterationForm";
import cardimg2 from "./WebAssets/cardimg2.png";
import cardimg3 from "./WebAssets/cardimg3.png";
import cardimg4 from "./WebAssets/cardimg4.png";
import axios from "axios";

export default function Signup() {
  const [data, setData] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(1);

  const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value);
  };

  useEffect(() => {
    axios
      .get("https://carwashapis.gosmart.ae/details_of/package_pricing/package_id/1")
      .then((response) => {
        const fetchedData = response.data.data;
        setData(fetchedData);
             })
      .catch((error) => {
        console.error("Error fetching the package details:", error);
      });

  }, []);

  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="row all-content" >
          <div className="col-xl-6 col-md-12" >
            <div className="Plan-selection">
              <h6>Select Your Plan:</h6>

              {data.map((plan) => (
        <div className="plan-container" key={plan.id}>
          <input
            type="radio"
            value={plan.id}
            checked={selectedPlan == plan.id}
            onChange={handlePlanChange}
          />
          <span className="rent-text">
            Subscribe for {plan.package_name}  for <strong>{plan.period}</strong>
          </span>
          <span className="rent-span-pull-right">{plan.price}$</span>
        </div>
      ))}

{/* 
              <div className="plan-container">
                <input
                  type="radio"
                  value="plan1"
                  checked={selectedPlan === "plan1"}
                  onChange={handlePlanChange}
                />
                <span className="rent-text">
                  {" "}
                  Renting the application for <strong>3 Months</strong>{" "}
                </span>
                <span className="rent-span-pull-right">50$</span>
              </div>
              <br />
              <div className="plan-container">
                <input
                  type="radio"
                  value="plan2"
                  checked={selectedPlan === "plan2"}
                  onChange={handlePlanChange}
                />
                <span className="rent-text">
                  {" "}
                  Renting the application for <strong>6 months</strong>{" "}
                </span>{" "}
                <span className="rent-span-pull-right">300$</span>
              </div>

              <br />
              <div className="plan-container">
                <input
                  type="radio"
                  value="plan3"
                  checked={selectedPlan === "plan3"}
                  onChange={handlePlanChange}
                />
                <span className="rent-text">
                  {" "}
                  Renting the application for <strong>Year</strong>
                </span>{" "}
                <span className="rent-span-pull-right">600$</span>
              </div>
              <br /> */}
            </div>
            <div className="Featuresweb">
              <h5>Features</h5>
              <div className="app-card2 shadow bg-body rounded">
                <div className="app-card-left">
                  <h6>Reservations management</h6>
                  <p>
                    Providing a smooth and comfortable experience for customers,
                    improving time and resource management for the organization,
                    enhancing work efficiency, and maximizing economic and
                    operational potential. Reservation management plays a
                    crucial role in the success of businesses and achieving
                    customer satisfaction.
                  </p>
                </div>
                <div className="app-card-right">
                  <img src={applicationsimg} />
                </div>
              </div>
              <div className="app-card2 shadow bg-body rounded">
                <div className="app-card-left">
                 <h6>Response speed</h6>
              <p>
              Where reservations are made or processed. This can include various aspects such as the time it takes customers to book appointments, the speed of confirmation of reservations, and the overall responsiveness of the reservation system
              </p>
                </div>
                <div className="app-card-right">
                  <img src={cardimg2} />
                </div>
              </div>
            </div>
          </div>
           <div className="col-xl-6 col-md-12">
            <RegisterationForm  selectedPlan={selectedPlan} />
          </div>  
        </div>
        <div className="Featuresmob">
              <h5>Features</h5>
              <div className="app-card2 shadow bg-body rounded">
                <div className="app-card-left">
                  <h6>Reservations management</h6>
                  <p>
                    Providing a smooth and comfortable experience for customers,
                    improving time and resource management for the organization,
                    enhancing work efficiency, and maximizing economic and
                    operational potential. Reservation management plays a
                    crucial role in the success of businesses and achieving
                    customer satisfaction.
                  </p>
                </div>
                <div className="app-card-right">
                  <img src={applicationsimg} />
                </div>
              </div>
              <div className="app-card2 shadow bg-body rounded">
                <div className="app-card-left">
                  <h6>Reservations management</h6>
                  <p>
                    Providing a smooth and comfortable experience for customers,
                    improving time and resource management for the organization,
                    enhancing work efficiency, and maximizing economic and
                    operational potential. Reservation management plays a
                    crucial role in the success of businesses and achieving
                    customer satisfaction.
                  </p>
                </div>
                <div className="app-card-right">
                  <img src={cardimg2} />
                </div>
              </div>
            </div>
        <div className="right-left-app-card">
          <div className="app-card2 shadow bg-body rounded">
            <div className="app-card-left">
            <h6>Add services</h6>
              <p>
              It allows the fresher to stay competitive in a dynamic market and adapt to customers and their changing preferences over time
              </p>
            </div>
            <div className="app-card-right">
              <img src={cardimg3} />
            </div>
          </div>

          <div className="app-card2 shadow bg-body rounded">
            <div className="app-card-left">
            <h6>Account management</h6>
              <p>
              Foster long-term customer relationships, increase customer satisfaction, and ultimately contribute to the success and growth of the business
              </p>
            </div>
            <div className="app-card-right">
              <img src={cardimg4} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
