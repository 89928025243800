import React, { useState, useEffect } from "react";
import "./WebStyles/Register.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { useParams, useHistory } from 'react-router-dom';
import PaymentPage from "./pamentpage";
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';

const RegisterationForm = ({ selectedPlan }) => {

  const [phone, setPhone] = useState("");
  const [apiId, setApiId] = useState(null);
  const [url, seturl] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [productId, setProductId] = useState(null);

  const history = useHistory();
  const [values, setValues] = useState({
    first_name: "",
    center_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    confirm_password: ""
  });

  const [message, setMessage] = useState("");
  const [passwordMessage, setPasswordMessage] = useState("");


  const handleregister = async (event) => {
    event.preventDefault(); // Prevent default form submission
    if (values.password !== values.confirm_password) {
      setPasswordMessage("Passwords do not match");
      return;
    }

    setPasswordMessage("");
    try {
      // Check if email exists
      const emailCheckResponse = await axios.get(`https://carwashapis.gosmart.ae/check_admin_data/${values.email}`);
      const phoneCheckResponse = await axios.get(`https://carwashapis.gosmart.ae/check_admin_data/${values.phone}`);

  
      if (emailCheckResponse.data.status === 0 && phoneCheckResponse.data.status === 0) {

        console.log("Email NOT exists");
        const registerResponse = await axios.post("https://carwashapis.gosmart.ae/create_record", {
          table_name: "carwash.admin",
          data: values
        });
        const idadmin = registerResponse.data.id; // Access the id property from the response data


        // console.log(emailCheckResponse.data.admin_id)
        // const existedid = emailCheckResponse.data.admin_id
        const checkoutResponse2 = await axios.get(`https://carwashapis.gosmart.ae/checkout_strip/${idadmin}/${selectedPlan}`);
        const checkoutUrl = checkoutResponse2.data['url'];
        const Session_id = checkoutResponse2.data['session_id'];
        const product_id = checkoutResponse2.data['product_id'];
        setSessionId(Session_id);
        setProductId(product_id);

        console.log("Checkout URL:", checkoutUrl);
        window.location.href= checkoutUrl ;

        //  window.open(checkoutUrl, "_blank");

        // Display message in form
        // You can use a state variable to display this message in your form
        setMessage("Email already exists");
        return;
      }
      // the email is already created but dont pay 
      else {
        console.log("Email or phone already exists");
        const check_sub = await axios.get (`https://carwashapis.gosmart.ae/check_subscription_data/${values.email}`)
        const check_sub2 = await axios.get (`https://carwashapis.gosmart.ae/check_subscription_data/${values.phone}`)
        console.log(check_sub)
        if (check_sub.data.status === 0 && check_sub2.data.status === 0 ) {
          console.log(check_sub.data.status); 
          console.log(check_sub2.data.status);
          console.log("This admin is not subscription")
          const admin_not_sub = check_sub.data['admin_id'];
          const checkoutResponse2 = await axios.get(`https://carwashapis.gosmart.ae/checkout_strip/${admin_not_sub}/${selectedPlan}`);
          const checkoutUrl = checkoutResponse2.data['url'];
          const Session_id = checkoutResponse2.data['session_id'];
          const product_id = checkoutResponse2.data['product_id'];
          setSessionId(Session_id);
          setProductId(product_id);
          window.location.href= checkoutUrl ;

        }

        else{

          setMessage("You are Already Subscribed");
          console.log("Subscribed")

        }
      }

    } catch (err) {
      console.error("Error:", err);
      // Display error message in form
      setMessage("An error occurred. Please try again.");
    }
  };


  
      // Check if phone exists
      // const phoneCheckResponse = await axios.get(`https://carwashapis.gosmart.ae/check_admin_data/${values.phone}`);
  
      // if (phoneCheckResponse.data.status === 1) {
      //   console.log("Phone number already exists");
      //   console.log(emailCheckResponse.data.admin_id)
      //   const existedid = emailCheckResponse.data.admin_id
      //   const checkoutResponse2 = await axios.get(`https://carwashapis.gosmart.ae/checkout_strip/${existedid}/${selectedPlan}`);
      //   const checkoutUrl = checkoutResponse2.data['url'];

      //   console.log("Checkout URL:", checkoutUrl);
  
      //   // window.open(checkoutUrl, "_blank");
      //   // Display message in form
      //   // You can use a state variable to display this message in your form
      //   setMessage("Phone number already exists");
      //   return;
      // }
  
      // Proceed with registration
      // const registerResponse = await axios.post("https://carwashapis.gosmart.ae/create_record", {
      //   table_name: "carwash.admin",
      //   data: values
      // });
  
      // console.log("Response:", registerResponse.data);
      // const idadmin = registerResponse.data.id; // Access the id property from the response data
      // console.log("ID:", idadmin);
  
      // const checkoutResponse = await axios.get(`https://carwashapis.gosmart.ae/checkout_strip/${idadmin}/${selectedPlan}`);
      // console.log("Second API Response:", checkoutResponse);
      // // seturl(checkoutResponse.data['url'])
      // const checkoutUrl = checkoutResponse.data['url'];
      // const Session_id = checkoutResponse.data['session_id'];
      // const product_id = checkoutResponse.data['product_id'];

      // setSessionId(Session_id);
      // setProductId(product_id);


      // console.log("Checkout URL:", checkoutUrl);

      // history.push(`/payment/${Session_id}/${product_id}`);


      // window.location.href= checkoutUrl ;



      // const check_session = await axios.get(`https://carwashapis.gosmart.ae/check_session/${Session_id}`)
      // console.log("third API Response:", check_session);

      // if (check_session.data.status === "paid") {


      // const create_new_subscription = await axios.get(`https://carwashapis.gosmart.ae/create_new_subscription/${Session_id}/${product_id}`)
      // console.log("third API Response:", create_new_subscription);
      // console.log("Session is paid. Proceeding...");

      // } else{

      //   console.log("Session is not paid. Exiting...");

      // }



      // Handle the response from the second API if needed

  return (
    <>
      <div className="registeration-Form shadow-sm p-3 mb-5 bg-body rounded">
        <form className="Reg-form" onSubmit={handleregister}>
          <h6 className="pb-2">Registeration Form</h6>
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">
              First Name
            </label>
            <input
              type="text"
              class="form-control custom-input"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              onChange={(e) => setValues({ ...values, first_name: e.target.value })}
              required
            />
          </div>

          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">
              Last Name
            </label>
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              onChange={(e) => setValues({ ...values, last_name: e.target.value })}
              required
            />
          </div>
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">
              Center Name
            </label>
            <input
              type="text"
              class="form-control custom-input"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              onChange={(e) => setValues({ ...values, center_name: e.target.value })}
              required
            />
          </div>
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">
              Email
            </label>
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              onChange={(e) => setValues({ ...values, email: e.target.value })}
              required
            />
          </div>
         
       <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">
              Phone
            </label>
      <PhoneInput

        country={'us'}
        value={phone}
        onChange={(phone) => {
          setPhone(phone);
          setValues({ ...values, phone: phone });
        }}
        inputStyle={{ width: '100%' }}  // Set width to 100%

      />
          </div>
          <div class="mb-3 pt-3">
            <label for="exampleInputPassword1" class="form-label">
              Password
            </label>
            <input
              type="password"
              class="form-control"
              id="exampleInputPassword1"
              onChange={(e) => setValues({ ...values, password: e.target.value })}
              required
            />
          </div>
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">
              Confirm Password
            </label>
            <input
              type="password"
              class="form-control"
              id="exampleInputPassword1"
              onChange={(e) => setValues({ ...values, confirm_password: e.target.value })}
              required
            />
          </div>

          <div className="Register-btn-sec">

            <button>Register Now</button>
          </div>
          {passwordMessage && <div className="alert alert-danger">{passwordMessage}</div>}
          {message && <p style={{ color: 'red' }}>{message}</p>}
        </form>
      </div>
   
    </>
  );
}
export default RegisterationForm;
